import { Link } from "gatsby"
import React, { useState } from "react"
import Logo from "./logo"

const Navbar = () => {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <nav className="flex items-center justify-between flex-wrap p-6 mb-6">
      <div className="logo flex items-center flex-shrink-0 text-white mr-6 w-100 h-100">
        <Link to={`/`} title="Go to the home page">
          <Logo />
        </Link>
      </div>
      <div className="block sm:hidden">
        <button
          onClick={() => toggleExpansion(!isExpanded)}
          className="flex items-center px-3 py-2 text-white border-none hover:text-white hover:border-none"
        >
          <svg
            className="fill-current h-5 w-5"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        className={`${
          isExpanded ? `block` : `hidden`
        } w-full block  sm:flex sm:items-center sm:w-auto`}
      >
        <div className="text-md sm:self-end sm:text-lg">
          <Link
            to={`/about-us`}
            className="block mt-6 sm:inline-block sm:mt-0 text-white hover:text-gray-200 mr-4"
          >
            About Us
          </Link>
          <Link
            to={`/services`}
            className="block mt-4 sm:inline-block sm:mt-0 text-white hover:text-gray-200 mr-4"
          >
            Services
          </Link>
          <Link
            to={`/gallery`}
            className="block mt-4 sm:inline-block sm:mt-0 text-white hover:text-gray-200 mr-4"
          >
            Gallery
          </Link>
          <Link
            to={`/testimonials`}
            className="block mt-4 sm:inline-block sm:mt-0 text-white hover:text-gray-200 mr-4"
          >
            Testimonials
          </Link>
          <Link
            to={`/contact`}
            className="block mt-4 sm:inline-block sm:mt-0 text-white hover:text-gray-200 mr-4"
          >
            Contact
          </Link>
          <Link
            to={`/blog`}
            className="block mt-4 sm:inline-block sm:mt-0 text-white hover:text-gray-200 mr-4"
          >
            Blog
          </Link>
          <Link
            to={`/faq`}
            className="block mt-4 sm:inline-block sm:mt-0 text-white hover:text-gray-200 mr-4"
          >
            FAQ's
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
