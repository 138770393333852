import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import "../css/global.css"
import Navbar from "./navbar"
import FB from "./fb"
import IG from "./ig"

const Layout = ({ children }) => {
  return (
    <div className="background min-h-screen text-white flex flex-col">
      <Navbar />
      <div className="c-wrap">
        <main>{children}</main>
      </div>
      <footer className="text-center">
        <div className="py-2">
          <span className="social-icons-footer">
            <a
              href="https://facebook.com/trainingfourpaws"
              title="Go to facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FB />
            </a>
            &nbsp;
            <a
              href="https://instagram.com/trainingfourpaws"
              title="Go to Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IG />
            </a>
            &nbsp;&nbsp;
          </span>
          &copy;&nbsp;{new Date().getFullYear()} Training Four Paws&nbsp; <br />
          <span className="text-xs">
            <Link to="/terms" title="Terms and Conditions">
              Terms
            </Link>{" "}
            |{" "}
            <Link to="/privacy-policy" title="Privacy Policy">
              Privacy Policy
            </Link>{" "}
            |{" "}
            <Link to="/cancellation-policy" title="Cancellations">
              Cancellations
            </Link>
          </span>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
